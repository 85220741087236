<template>
  <div class="page">
    <img class="banner" src="./img/home-banner.png" alt="" />
    <div class="optList">
      <div
        class="item"
        @click="toSomeWhere(item)"
        v-for="(item, index) in optionsList"
        :key="index"
      >
        <img :src="item.src" alt="" />
        <div class="title">{{ item.title }}</div>
      </div>
    </div>
    <img
      class="djPlace"
      @click="toSomeWhere(dangjianObj)"
      src="./img/djPlace.png"
      alt=""
    />
    <div class="main-title" @click="toSomeWhere(fengcaiObj)">
      <div class="title">风采展示</div>
      <div class="more">更多</div>
      <img src="./img/black-arrow.png" alt="" />
    </div>
    <div class="list">
      <div
        class="item"
        @click="toRouteTitle(item)"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="item-l">
          <div class="title">
            {{ item.topicTitle || "" }}
          </div>
          <div class="bottom">
            <div class="time">{{ item.createTime }}</div>
            <div class="sum">
              <img src="./img/lookIcon.png" alt="" />
              <div>{{ item.readCount }}</div>
            </div>
          </div>
        </div>
        <div class="item-r">
          <img :src="item.topicPicture" />
        </div>
      </div>
    </div>
    <div class="btn" @click="toSomeWhere(wuyeObj)">物业服务</div>
  </div>
</template>

<script>
import { getTopicListURL } from "./api.js";
import { gloabalCount } from "@/utils/common";
import wx from "weixin-js-sdk";
export default {
  name: "threedWorkMethods",
  data() {
    return {
      fengcaiObj: {
        name: "styleExhibition",
      },
      dangjianObj: {
        name: "partyBuildingPosition",
      },
      wuyeObj: {
        name: "propertyServices",
      },
      optionsList: [
        {
          src: require("./img/qdj.png"),
          title: "强党建",
          name: "strengthenPartyBuilding",
        },
        {
          src: require("./img/dpt.png"),
          title: "搭平台",
          name: "buildPlatform",
        },
        {
          src: require("./img/lzd.png"),
          title: "立制度",
          name: "establishSsystem",
        },
        {
          src: require("./img/jdw.png"),
          title: "建队伍",
          name: "buildTeam",
        },
        {
          src: require("./img/jscx.png"),
          title: "坚守初心",
          name: "stickPrimacy",
        },
      ],
      list: [],
    };
  },
  mounted() {
    gloabalCount("", 86, 1);
    localStorage.removeItem("curIndex");
    this.getList();
  },
  methods: {
    getList() {
      let params = {
        curPage: 1,
        pageSize: 3,
        tenantId: this.tenantId,
        houseId: this.houseId || this.communityId || undefined,
        sortField: "NEW",
        topicType: "26",
      };
      this.$axios.get(`${getTopicListURL}`, { params }).then((res) => {
        if (res.code === 200) {
          this.list = res.data.records;
        }
      });
    },
    // 点击跳转话题详情页
    toRouteTitle(item) {
      let params = {
        id: item.id,
      };
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/subPages/topic/topicTitle?params=${JSON.stringify(params)}`,
        });
      }
    },
    toSomeWhere(item) {
      this.$router.push({
        name: item.name,
        query: {
          curIndex: 0,
        },
      });
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  padding: 26px 30px 26px;
  box-sizing: border-box;
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffbf54 0%, #fea12e 100%);
    box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
    border-radius: 16px;
    text-align: center;
    line-height: 66px;
    font-size: 26px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    margin: 0 auto;
  }
  .list {
    margin-top: 38px;
    margin-bottom: 80px;
    .item {
      display: flex;
      width: 100%;
      height: 188px;
      padding: 16px 0 20px 0;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      .item-l {
        flex: 1;
        position: relative;
        .title {
          font-size: 32px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 1);
          line-height: 44px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .bottom {
          width: 100%;
          display: flex;
          align-items: center;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #b4b4b4;
          position: absolute;
          bottom: 0;
          .time {
            white-space: nowrap;
          }
          .sum {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              margin-right: 2px;
              width: 26px;
            }
          }
        }
      }
      .item-r {
        width: 226px;
        height: 100%;
        img {
          border-radius: 12px;
          width: 226px;
          height: 150px;
        }
      }
    }
  }
  .main-title {
    display: flex;
    align-items: center;
    .title {
      font-size: 36px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #1a1c34;
      flex: 1;
    }
    .more {
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
    }
    img {
      width: 12px;
      margin-left: 10px;
    }
  }
  .optList {
    display: flex;
    justify-content: space-between;
    .item {
      width: 108px;
      img {
        width: 108px;
        height: 108px;
      }
      .title {
        white-space: nowrap;
        // margin-top: 18px;
        text-align: center;
        font-size: 28px;
        font-weight: 600;
        color: #1a1c34;
      }
    }
  }
  .djPlace {
    width: 100%;
    margin: 32px 0 32px 0;
  }
  .banner {
    width: 100%;
    margin-bottom: 30px;
  }
}
</style>